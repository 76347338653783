import React, {useEffect, useState, memo} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import {
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    //   DialogTitle,
    //   DialogContent,
    //   DialogActions,
    //   Grid,
    //   Divider,
    //   TextField,
    //   MenuItem,
    Slide,
    TextField,
    //   DialogContentText,
    //   TableCell,
    //   TableRow,
    //   TableHead,
    //   TableBody,
    //   Paper,
    //   TableContainer,
    //   IconButton,
} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {crud} from "../services/CrudFactory";
import {makeStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {Pagination} from "pagination-ui";
import moment from "moment";


function PaymentDetailsDialog(props) {
    console.log(props.id, "=================props.id")
    const useStyles = makeStyles({
        table: {
            minWidth: 650,
        },
    });
    const classes = useStyles();

    const [professionals, setProfessionals] = useState([]);
    const [total, setTotal] = useState(0);
    // const data = [
    //   {
    //     id: 1,
    //     amount: 200,
    //     category: "abc",
    //     service: "jasdvg",
    //     qty: 123,
    //     paymentType: "cash",
    //     paymentStatus: "success",
    //   },
    // ];
    function createData(id, jobOrderId, PaymentType, total, RevenuePercentage, RevenueTotal, PaymentStatus) {
        return {id, jobOrderId, PaymentType, total, RevenuePercentage, RevenueTotal, PaymentStatus};
    }

    const changeStatus = async (e, val) => {
        const {
            data
        } = await crud.update(`update/web/payment`, {id: val});
        // if (data.type == "success") {
        getPayments();
        // }
    };
    const getPayments = async () => {
        try {
            const {data: {payments}} = await crud.get(`retrieve/web/payments?professionalId=${props.id.id}`);
            setProfessionals(payments)
        } catch (e) {
            console.log(e)
        }

    }
    useEffect(async () => {
        getPayments();
    }, [props.id.id]);

    return (
        // <div>
        <Dialog
            fullWidth
            TransitionComponent={Slide}
            // aria-labelledby="customized-dialog-title"
            open={props.open}
            maxWidth={"lg"}
            // TransitionProps={{
            //   onEnter: () => {},
            // }}
            onClose={props.onClose}
        >
            <Grid
                container
                xs={12}
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item>
                    <DialogTitle>Payments Details</DialogTitle>
                </Grid>

                <Grid item>
                    <DialogTitle>
                        <IconButton onClick={props.onClose}>
                            <Close/>
                        </IconButton>
                    </DialogTitle>
                </Grid>
            </Grid>
            <Divider/>

            <DialogContent>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{background: "#e466a3", color: "#fff"}}>
                                    id
                                </TableCell>
                                <TableCell style={{background: "#e466a3", color: "#fff"}}>
                                    jobOrderId
                                </TableCell>
                                <TableCell style={{background: "#e466a3", color: "#fff"}}>
                                    Payment Type
                                </TableCell>
                                <TableCell style={{background: "#e466a3", color: "#fff"}}>
                                    Total
                                </TableCell>
                                <TableCell style={{background: "#e466a3", color: "#fff"}}>
                                    Revenue Percentage
                                </TableCell>
                                <TableCell style={{background: "#e466a3", color: "#fff"}}>
                                    Total Professional Amount
                                </TableCell>
                                <TableCell style={{background: "#e466a3", color: "#fff"}}>
                                    Total company Amount
                                </TableCell>
                                <TableCell style={{background: "#e466a3", color: "#fff"}}>
                                    Date
                                </TableCell>
                                <TableCell
                                    align="right"
                                    style={{background: "#e466a3", color: "#fff"}}
                                >
                                    Payment Status&nbsp;
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {professionals.map((row) => {
                                const totalAmount = row?.total - +row?.order?.discount + Number(row?.order?.additional_services?.reduce((acc, item) => {
                                    return Number(acc) + +item.price;
                                }, []))
                                const professionalAmt = ((Number(props?.id.revenuePercentage) || 0) * (Number(totalAmount) || 0)) / 100
                                return (
                                    <TableRow key={row.name}>
                                        <TableCell component="th" scope="row">
                                            {row.id}
                                        </TableCell>
                                        <TableCell>{row.jobOrderId}</TableCell>
                                        <TableCell>{row.paymentType}</TableCell>
                                        <TableCell>{totalAmount}</TableCell>
                                        <TableCell>{props?.id.revenuePercentage && (props?.id.revenuePercentage + "%" || "0%")}</TableCell>
                                        {/*<TableCell> ₹{(row.total - (row.total * (props?.id.revenuePercentage / 100))).toFixed(2)}</TableCell>*/}
                                        <TableCell> ₹{totalAmount - professionalAmt}</TableCell>
                                        <TableCell> ₹{professionalAmt}</TableCell>
                                        {/*<TableCell>₹{(row.total * (props?.id.revenuePercentage / 100)).toFixed(2)}</TableCell>*/}
                                        <TableCell>{moment(row.createdAt).format('DD-MM-YYYY')}</TableCell>
                                        <TableCell align="right">
                                            {console.log(row.paymentStatus)}
                                            <TextField
                                                select
                                                size='small'
                                                variant="outlined"
                                                disabled={row.paymentStatus == "received" || row.paymentStatus == "completed"}
                                                defaultValue={row.paymentStatus}
                                                label="Status"
                                                onChange={(e) => changeStatus(e, row?.id)}
                                            >
                                                <MenuItem value="received">Received</MenuItem>
                                                <MenuItem value="pending">Pending</MenuItem>
                                                {
                                                    row.paymentStatus !== "pending" &&
                                                    <MenuItem value="completed">Completed</MenuItem>

                                                }
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>

            <Pagination count={10} variant="outlined"/>
            {/* <Paper> */}
            {/* <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead stickyHeader size="small">
              <TableRow>
                <TableCell style={{ background: "#e466a3", color: "#fff" }}>
                  Id
                </TableCell>
                <TableCell
                  style={{ background: "#e466a3", color: "#fff" }}
                  align="right"
                >
                  Amount
                </TableCell>
                <TableCell
                  style={{ background: "#e466a3", color: "#fff" }}
                  align="right"
                >
                  Category
                </TableCell>
                <TableCell
                  style={{ background: "#e466a3", color: "#fff" }}
                  align="right"
                >
                  Service
                </TableCell>
                <TableCell
                  style={{ background: "#e466a3", color: "#fff" }}
                  align="right"
                >
                  Qty
                </TableCell>
                <TableCell
                  style={{ background: "#e466a3", color: "#fff" }}
                  align="right"
                >
                  Payment Type
                </TableCell>
                <TableCell
                  style={{ background: "#e466a3", color: "#fff" }}
                  align="right"
                >
                  Payment Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow
                  key={row?.id}
                //   sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row?.id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row?.amount}
                  </TableCell>
                  <TableCell align="right">{row?.category}</TableCell>
                  <TableCell align="right">{row?.service}</TableCell>
                  <TableCell align="right">{row?.qty}</TableCell>
                  <TableCell align="right">{row?.paymentType}</TableCell>
                  <TableCell align="right">{row?.paymentStatus}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}
            {/* </Paper> */}
        </Dialog>
        // </div>
    );
}

export default memo(PaymentDetailsDialog);