import React, {Fragment} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import CustomerUser from "../page/CustomerUser";
import ProfessionalUser from "../page/ProfessionalUser";
import Dashboard from "../page/Dashboard";
import Login from "../session/Login";
import Kyc from "../page/Kyc";
import Notification from "../page/Notification";
import SupportTicket from "../page/SupportTicket";
import Faqs from "../page/Faqs";
import AboutUs from "../page/AboutUs";
import PanelUser from "../page/PanelUser";
import Sliders from "../page/Sliders";
import Jobs from "../page/Jobs";
import Categories from "../page/Categories";
import Product from "../page/Service";
import Logout from "../session/Logout";
import PrivateRoute from "./PrivateRoute";
import SubCategories from "../page/SubCategories";
import Payments from "../page/Payments";
import Statistics from "../page/Statistics";

export default function Routing() {
    return (
        <>
            <BrowserRouter basename='/'>
                <Routes>
                    <Route path="/" element={<Login/>}/>
                    <Route path="/dashboard" element={
                        <PrivateRoute>
                            <Dashboard/>
                        </PrivateRoute>}/>
                    <Route path="/statistics" element={
                        <PrivateRoute>
                            <Statistics/>
                        </PrivateRoute>}/>

                    <Route path="/logout" element={
                        <PrivateRoute>
                            <Logout/>
                        </PrivateRoute>}/>

                    {/*customer user route*/}
                    <Route path="/customer-user" element={
                        <PrivateRoute>
                            <CustomerUser/>
                        </PrivateRoute>}/>

                    {/*professional-user route*/}
                    <Route path="/professional-user" element={
                        <PrivateRoute>
                            <ProfessionalUser/>
                        </PrivateRoute>}/>

                    {/*kyc route*/}
                    <Route path="/professional-kyc" element={
                        <PrivateRoute>
                            <Kyc/>
                        </PrivateRoute>}/>


                    <Route path="/notification" element={
                        <PrivateRoute>
                            <Notification/>
                        </PrivateRoute>}/>

                    <Route path="/support-ticket" element={
                        <PrivateRoute>
                            <SupportTicket/>
                        </PrivateRoute>}/>

                    <Route path="/faqs" element={
                        <PrivateRoute>
                            <Faqs/>
                        </PrivateRoute>}/>

                    <Route path="/about-us" element={
                        <PrivateRoute>
                            <AboutUs/>
                        </PrivateRoute>}/>

                    <Route path="/panel-users" element={
                        <PrivateRoute>
                            <PanelUser/>
                        </PrivateRoute>}/>

                    <Route path="/jobs" element={
                        <PrivateRoute>
                            <Jobs/>
                        </PrivateRoute>}/>

                    <Route path="/support-ticket" element={
                        <PrivateRoute>
                            <SupportTicket/>
                        </PrivateRoute>}/>

                    <Route path="/sliders" element={
                        <PrivateRoute>
                            <Sliders/>
                        </PrivateRoute>}/>

                    <Route path="/categories" element={
                        <PrivateRoute>
                            <Categories/>
                        </PrivateRoute>}/>

                    <Route path="/services" element={
                        <PrivateRoute>
                            <Product/>
                        </PrivateRoute>}/>

                    <Route path="/payments" element={
                        <PrivateRoute>
                            <Payments/>
                        </PrivateRoute>}/>

                    <Route path="/sub-categories" element={<SubCategories/>}/>
                    <Route path="*" element={<Login/>}/>
                </Routes>
            </BrowserRouter>
        </>
    );
}
