import React, {useEffect, useState} from "react";
import {crud} from "../services/CrudFactory";
import Edit from "@material-ui/icons/Edit";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DeleteIcon from "@material-ui/icons/Block";
import {Container, Grid, Typography,Button,IconButton} from "@material-ui/core";
import Appbar from "../components/Appbar";
import Drawer from "../components/Drawer";
import Footer from "../components/Footer";

function Sliders() {
    const [loader, setLoader] = useState(true);
    const [imageData, setImageData] = useState([]);
    const [previewImages, setPreviewImages] = useState({});
    const [uploadedImages, setUploadedImages] = useState({});

    const getImageData = async () => {
        const {data: {query}} = await crud.get("retrieve/web/slider");
        setImageData(query);
        setLoader(false);
    };

    useEffect(() => {
        getImageData();
    }, []);

    const handleImageUpload = (e, id) => {
        const file = e.target.files[0];
        if (file) {
            // Update previewImages for the specific slider
            const reader = new FileReader();
            reader.onload = (event) => {
                setPreviewImages((prev) => ({...prev, [id]: event.target.result}));
            };
            reader.readAsDataURL(file);

            // Save the file in uploadedImages for later submission
            setUploadedImages((prev) => ({...prev, [id]: file}));
        }
    };

    const categorySubmit = async (id) => {
        if (!uploadedImages[id]) return; // No new image to upload

        const formData = new FormData();
        formData.append("id", id);
        formData.append("image", uploadedImages[id]);

        await crud.post("update/web/slider", formData);

        // Reset states after successful upload
        setUploadedImages((prev) => {
            const {[id]: _, ...rest} = prev;
            return rest;
        });
        setPreviewImages((prev) => {
            const {[id]: _, ...rest} = prev;
            return rest;
        });

        // Refresh data
        getImageData();
    };


    const changeStatus = async (id) => {
        await crud.post(`status/web/slider?id=${id}`);
        await getImageData();
    }

    return (
        <>
            <Appbar/>
            <Drawer/>
            <Container maxWidth="xl" style={{marginTop: "30px"}}>
                <Typography variant={"h6"} className={"font-weight-bolder mb-1"}>Slider</Typography>
                <Grid container spacing={4}>
                    {imageData.map((item) => (
                        <Grid item xs={12} sm={6} md={4} key={item.id}>
                            <input
                                type="file"
                                accept="image/*"
                                name={`image-${item.id}`}
                                onChange={(e) => handleImageUpload(e, item.id)}
                                style={{display: "none"}}
                                id={`upload-${item.id}`}
                            />
                            <div className="man">
                                <img
                                    src={previewImages[item.id] || `${crud.baseUrl2}sliders/${item.image}`}
                                    className="imgbtn"
                                    alt="slider preview"
                                />
                            </div>
                            <div className="editBtn">
                                <IconButton
                                    style={{background: "#e466a3", color: "#FFFFFF", marginRight: "5px"}}
                                    onClick={() => document.getElementById(`upload-${item.id}`).click()}
                                >
                                    <Edit className="p-1"/>
                                </IconButton>

                                <IconButton
                                    style={{background: "#e466a3", color: "#FFFFFF", marginRight: "5px"}}
                                    onClick={() => categorySubmit(item.id)}
                                >
                                    <DoneAllIcon className="p-1"/>
                                </IconButton>

                                <Button
                                    style={{background: !item?.status ?"#e466a3" : "#d43535", color: "#FFFFFF", marginRight: "5px"}}
                                    onClick={() => changeStatus(item.id)}
                                    size={"small"}
                                >
                                    {item?.status ? "InActive" : "Active"}
                                </Button>
                            </div>
                        </Grid>
                    ))}
                </Grid>
                <Footer/>
            </Container>
        </>
    );
}

export default Sliders;
