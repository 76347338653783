import React, {Fragment, useEffect, useState} from "react";
import Routing from "./router/Routing";
import "./App.css";
import {MuiThemeProvider, createTheme} from "@material-ui/core/styles";
import {onMessageListener} from "./services/firebase";
import {socket} from "./services/socket"
// use default theme
// const theme = createMuiTheme();

// Or Create your Own theme:
const theme = createTheme({
    palette: {
        primary: {
            main: "#e466a3",
            // main: "#a79292",
        },
        secondary: {
            main: "#5189CF",
        },
        background: {
            primary: "#e466a3"
        }
    },
});


function App() {

    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({title: '', body: ''});

    useEffect(() => {
        socket.connect()
        onMessageListener().then(payload => {
            setShow(true);
            setNotification({title: payload.notification.title, body: payload.notification.body})
            console.log(payload);
        }).catch(err => console.log('failed on message listener: ', err));
        // new Notification('sdgds',{body:"sjdfsdfh"})
    }, []);

    useEffect(() => {
        socket.on("new-job-arrived", (data) => {
            new Notification('New Job Arrived',{body:`Order No:  ${data?.orderData?.id}. from ${data?.orderData?.address?.city}, Service: ${data?.orderData?.sub_category?.name}`})
        });
        return () => {
            socket.off("new-job-arrived")
        };
    }, []);

    useEffect(() => {
        socket.on("job-rescheduled", (data) => {
            new Notification('Rescheduled Job Arrived',{body:`Order No:  ${data?.orderData?.id}.`})
        });
        return () => {
            socket.off("job-rescheduled")
        };
    }, []);

    return (
        <Fragment>
            <MuiThemeProvider theme={theme}>
                <Routing/>
            </MuiThemeProvider>
        </Fragment>
    );
}

export default App;
