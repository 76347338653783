import React, {Fragment, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import {
    DialogTitle, DialogContent, DialogActions, Grid, TextField, Select, MenuItem, FormControl
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {crud} from "../services/CrudFactory";
import Divider from '@material-ui/core/Divider';
import 'date-fns';
import SlotPicker from 'slotpicker';

const useStyles = makeStyles((theme) => ({
    root: {margin: 'auto',},
    fieldtitle: {minWidth: "300px !important", maxHeight: "250px"},
    gridItem: {marginTop: "25px",},
}));

export default function JobCreate(props) {
    const classes = useStyles();
    const [subCategories, setSubCategories] = useState([]);
    const [professionalIds, setProfessionalIds] = useState([]);
    const [loading, setLoading] = useState(false);
    const [params, setParams] = useState({});
    const [type, setType] = useState(null);
    const [categories, setCategories] = useState([]);
    const [state, setState] = useState("");
    const [showField, setShowField] = useState("success");
    const [showOTPField, setShowOTPField] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [order, setOrder] = useState({});
    const [formAddress, setFormAddress] = useState({})
    const [open, setOpen] = useState(false);
    const [services, setServices] = useState([]);

    const addAddress = async () => {
        try {
            const {data: {allAddress}} = await crud.post("create/web/customer-address", {
                ...formAddress,
                id: params.id
            });
            setParams({...params, customerAddress: allAddress})
        } finally {
            setOpen(false)
        }
    }

    const handleOpenDialog = () => {
        setOpen(true);
    };
    const handleCloseDialog = () => {
        setOpen(false);
    };
    const getServices = async (id) => {
        const {data: {services}} = await crud.get("retrieve/web/services-by-id", {id});
        setServices(services)

    }

    const getSubCategories = async (id) => {
        const {data: {subCategoryNames}} = await crud.get("retrieve/web/subCategories-by-id", {id});
        setSubCategories(subCategoryNames)
    }
    const categoriesNames = async () => {
        const {data: {categoriesNames}} = await crud.get("retrieve/web/categories-names");
        setCategories(categoriesNames);
    }

    const assignOrder = async () => {
        props.onClose();
        props.onSave();
    }
    const createOrder = async () => {
        setLoading(true)
        try {
            const {data} = await crud.post("create/web/order", order);
            props.onClose()
            setLoading(false)
        } catch (e) {
            console.log(e.message)
            setLoading(false)
        }
    }

    const InputChange = async (e) => {
        const {name, value} = e.target;
        if (name == "address" || name == "pincode" || name == "city" || name == "state" || name == "lat_lng" || name == "landmark") {
            setParams({...params, "customerAddress": {...params?.customerAddress, [name]: value}})
        } else {
            setParams({...params, [name]: value});
        }
    }

    const orderDetails = (e) => {
        const {name, value} = e.target;
        if (name === "categoryId") {
            getSubCategories(value)
        } else if (name === "subCategoryId") {
            getServices(value)
        }
        setOrder(prev => ({...prev, [name]: value}));
    };


    const updateUser = async () => {
        const data = await crud.post("update/web/customer/profile", params);
    }
    const Check = async () => {
        try {
            const data = await crud.get("retrieve/web/check-customer", params);
            setType(data.type)
            setParams(data?.data?.isCustomerExists)
            setShowField(data?.type);
            orderDetails({target: {name: "customerId", value: data.data.isCustomerExists.id}})

        } catch (e) {
            const {name, mobile} = e.errors.customer;
            setParams({
                name: name,
                check_mobile: mobile
            });

            if (e.message === "Customer not Exist") {
                setShowField(true)
                setType(e.type)
            }
        }
    }

    const SendOTP = async () => {
        const {data, type} = await crud.post("create/web/customer", {mobile: params?.check_mobile});
        if (type === "success") {
            setShowOTPField(true)
            setParams(prev => ({...prev, userId: data.customer.id, otp: data?.OTP}))
        }
    }

    const VerifyOTP = async () => {
        const data = await crud.post("create/app/customer/match-otp", {userId: params?.userId, otp: params.otp});
        if (data.type === "success") {
            setShowOTPField(false);
            setDisabled(true);
            setParams(prev => ({...prev, id: data?.data?.customer?.id, userId: ""}))
            orderDetails({target: {name: "customerId", value: data.data?.customer?.id}})
        }
    }

    useEffect(() => {
        categoriesNames();
    }, [order]);

    const onUserInputAddress = (e) => {
        setFormAddress({
            ...formAddress,
            [e.target.name]: e.target.value
        })
    }

    const selectedDate = new Date(order?.scheduledAt);
    const now = new Date();
    let pastHours = [];
    if (selectedDate.toDateString() === now.toDateString()) {
        const currentHour = (now.getHours() - 1);
        const startHour = 8;

        for (let i = currentHour; i >= startHour; i--) {
            pastHours.push(`${String(i).padStart(2, '0')}:00`);
        }
        console.log({pastHours});
    } else {
        // console.log(selectedDate, "selectedDate")
        const currentHour = (selectedDate.getHours() - 1);
        const startHour = 8;

        for (let i = currentHour; i >= startHour; i--) {
            pastHours.push(`${String(i).padStart(2, '0')}:00`);
        }

        // console.log({pastHours});
    }

    return (<Fragment>
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={props.open}
            maxWidth={"md"}
            TransitionProps={{
                onEnter: () => {
                    setOrder(props.assign);
                    if (props.assign?.job_order_professionals?.length)
                        setProfessionalIds(props.assign?.job_order_professionals?.map(item => item?.professionalId))
                }
            }}
        >
            <DialogTitle>
                <Typography variant="h6" className="font-weight-bolder">Job Create</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <Typography variant="body2" className="mb-1">
                            Name
                        </Typography>
                        <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={params?.name || ""}
                            defaultValue={params?.name}
                            name="name"
                            onChange={InputChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Typography variant="body2" className="mb-1">
                            Mobile Number
                        </Typography>
                        <TextField
                            variant="outlined"
                            size="small"
                            type="number"
                            name="check_mobile"
                            onChange={InputChange}
                            defaultValue={params?.mobile}
                            FormHelperTextProps={{
                                style: {
                                    color: (type == "error") ? "red" : "green",
                                    display: (type == null) ? "none" : "flex"
                                }
                            }}
                            helperText={(type === "error") ? "Customer Not Exist" : "Customer Exist"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        {showField === "success" ?
                            <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                className={classes.gridItem}
                                onClick={Check}>
                                Check
                            </Button>
                            :
                            <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                className={classes.gridItem}
                                disabled={disabled}
                                onClick={SendOTP}>
                                send OTP
                            </Button>
                        }
                    </Grid>
                    {
                        showOTPField ?
                            <>
                                <Grid item xs={12} sm={2}>
                                    <Typography variant="body2" className="mb-1">
                                        OTP
                                    </Typography>
                                    <TextField
                                        size="small"
                                        name="otp"
                                        fullWidth
                                        value={params?.otp || ""}
                                        onChange={InputChange}
                                        variant="outlined"/>
                                </Grid>
                                <Grid item xs={12} sm={2} className={classes.gridItem}>
                                    <Button size="small" variant="contained" color="secondary" onClick={VerifyOTP}>Verify
                                        OTP </Button>
                                </Grid>
                            </> : null
                    }
                    <Grid container item xs={12} sm={6} spacing={1}>
                        <Grid item xs={10} sm={9}>
                            <Typography variant="body2" className="mb-1">
                                Address
                            </Typography>
                            <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
                                <Select name="addressId" onChange={orderDetails}>
                                    {params?.customerAddress?.map((item, index) => <MenuItem value={item.id}
                                                                                             key={item.id || index}>{item.address + "," + item.pincode + "," + item.city}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={1} sm={2}>
                            <Button variant="contained" color="secondary" onClick={handleOpenDialog}
                                    className={classes.gridItem}>Add</Button>
                        </Grid>
                    </Grid>
                    <Dialog open={open} onClose={handleCloseDialog} aria-labelledby="form-dialog-title" maxWidth="xs"
                            fullWidth={true}>
                        <DialogTitle id="form-dialog-title">Add Address</DialogTitle>
                        <DialogContent justifyContent="center">
                            <Grid container justifyContent="center">
                                <Grid item xs={12} sm={10}>
                                    <Typography variant="body2" className="mb-1">Nick Name</Typography>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        name="nickName"
                                        onChange={onUserInputAddress}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <Typography variant="body2" className="mb-1">Landmark</Typography>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        name="landmark"
                                        onChange={onUserInputAddress}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <Typography variant="body2" className="mb-1">Pincode</Typography>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        name="pincode"
                                        onChange={onUserInputAddress}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <Typography variant="body2" className="mb-1">City</Typography>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        name="city"
                                        onChange={onUserInputAddress}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <Typography variant="body2" className="mb-1">State</Typography>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        name="state"
                                        onChange={onUserInputAddress}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <Typography variant="body2" className="mb-1">Address</Typography>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        name="address"
                                        onChange={onUserInputAddress}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} variant="contained" color="primary">Cancel</Button>
                            <Button variant="contained" color="secondary" onClick={addAddress}>Submit</Button>
                        </DialogActions>
                    </Dialog>
                    <Grid item xs={12} sm={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body2" className="mb-1">Schedule At</Typography>
                        <TextField
                            style={{width: "100%",}}
                            InputLabelProps={{shrink: true}}
                            variant="outlined"
                            name="scheduledAt"
                            id="datetime-local"
                            size="small"
                            type="date"
                            format={"MM/DD/YYYY"}
                            value={order?.scheduledAt || ""}
                            onChange={orderDetails}
                            inputProps={{min: new Date().toISOString().split("T")[0]}}
                        />
                    </Grid>
                    {order?.scheduledAt && <Grid item xs={12} sm={6}>
                        <div className="SlotPickerParentDiv">
                            <Typography variant="body2">Select Slot</Typography>
                            <SlotPicker
                                interval={120}
                                onSelectTime={(from) => setOrder((pre) => ({
                                    ...pre,
                                    startTime: from.format("hh:mm A"),
                                    endTime: from.add(2, 'h').format("hh:mm A")
                                }))}
                                unAvailableSlots={pastHours}
                                from={'09:00'}
                                to={'18:00'}
                                // defaultSelectedTime={`${currentTimeInHH - 1}:00`}
                                selectedSlotColor='#129031'
                                lang='en'
                            />
                        </div>
                    </Grid>}
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body2" className="mb-1">Category</Typography>
                        <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
                            <Select
                                value={order?.category}
                                onChange={orderDetails}
                                name="categoryId"
                            >
                                {
                                    categories.map((item, index) => {
                                        return (
                                            <MenuItem value={item.id} key={item.id || index}>{item.name}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body2" className="mb-1">
                            Sub Category
                        </Typography>
                        <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
                            <Select
                                name="subCategoryId"
                                value={order?.subCategory}
                                onChange={orderDetails}
                            >
                                {
                                    subCategories?.map((item, index) => {
                                        return (
                                            <MenuItem value={item.id} key={item.id || index}>{item["name"]}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body2" className="mb-1">
                            Services
                        </Typography>
                        <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
                            <Select
                                name="serviceId"
                                onChange={orderDetails}
                            >
                                {
                                    services.map((item, index) => {
                                        return (
                                            <MenuItem value={item.id} key={index}>{item.title}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}
                          style={{marginTop: "20px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Typography variant="h6" className="mb-1" style={{color: 'green'}}>
                            Total
                            Amount: {services?.find((item) => order?.serviceId === item?.id)?.actualPrice.toString()}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                        setParams({})
                        props.onClose()
                        setProfessionalIds([]);
                    }}
                    style={{textTransform: "none"}}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={createOrder}
                    style={{textTransform: "none"}}
                    disabled={loading}
                >
                    {loading ? "Creating..." : "Create"}
                </Button>
            </DialogActions>
        </Dialog>
    </Fragment>);
}
