// module.exports = {SET_USER} = Symbol("SET_USER");
const {
    VIEW_JOB,
    VIEW_KYC,
    UPDATE_KYC,
    CREATE_NOTIFICATION,
    VIEW_NOTIFICATIONS,
    UPDATE_NOTIFICATION,
    DELETE_NOTIFICATION,
    SEND_NOTIFICATION,
    VIEW_SUPPORT_TICKET,
    REPLY_SUPPORT_TICKET,
    VIEW_CUSTOMERS,
    CHANGE_CUSTOMER_STATUS,
    VIEW_PROFESSIONALS,
    CHANGE_PROFESSIONAL_STATUS,
    VIEW_EMPLOYEES,
    CREATE_EMPLOYEE,
    UPDATE_EMPLOYEE,
    DELETE_EMPLOYEE,
    CHANGE_EMPLOYEE_STATUS,
    VIEW_SLIDER_IMAGES,
    UPDATE_SLIDER_IMAGE,
    VIEW_CATEGORIES,
    CREATE_CATEGORIES,
    UPDATE_CATEGORIES,
    CHANGE_CATEGORY_STATUS,
    VIEW_SUB_CATEGORIES,
    CREATE_SUB_CATEGORIES,
    UPDATE_SUB_CATEGORIES,
    CHANGE_SUB_CATEGORY_STATUS,
    VIEW_SERVICES,
    CREATE_SERVICE,
    UPDATE_SERVICE,
    DELETE_SERVICE,
    CHANGE_SERVICE_STATUS,
    VIEW_FAQ,
    CREATE_FAQ,
    UPDATE_FAQ,
    DELETE_FAQ,
    VIEW_ABOUT,
    UPDATE_ABOUT,
    CUSTOMER,
    PROFESSIONAL,
    VIEW_DASHBOARD,
    VIEW_STATISTICS,
    VIEW_PAYMENTS
} = require("./constants.json")

module.exports = {
    VIEW_JOB,
    VIEW_KYC,
    UPDATE_KYC,
    CREATE_NOTIFICATION,
    VIEW_NOTIFICATIONS,
    UPDATE_NOTIFICATION,
    DELETE_NOTIFICATION,
    SEND_NOTIFICATION,
    VIEW_SUPPORT_TICKET,
    REPLY_SUPPORT_TICKET,
    VIEW_CUSTOMERS,
    CHANGE_CUSTOMER_STATUS,
    VIEW_PROFESSIONALS,
    CHANGE_PROFESSIONAL_STATUS,
    VIEW_EMPLOYEES,
    CREATE_EMPLOYEE,
    UPDATE_EMPLOYEE,
    DELETE_EMPLOYEE,
    CHANGE_EMPLOYEE_STATUS,
    VIEW_SLIDER_IMAGES,
    UPDATE_SLIDER_IMAGE,
    VIEW_CATEGORIES,
    CREATE_CATEGORIES,
    UPDATE_CATEGORIES,
    CHANGE_CATEGORY_STATUS,
    VIEW_SUB_CATEGORIES,
    CREATE_SUB_CATEGORIES,
    UPDATE_SUB_CATEGORIES,
    CHANGE_SUB_CATEGORY_STATUS,
    VIEW_SERVICES,
    CREATE_SERVICE,
    UPDATE_SERVICE,
    DELETE_SERVICE,
    CHANGE_SERVICE_STATUS,
    VIEW_FAQ,
    CREATE_FAQ,
    UPDATE_FAQ,
    DELETE_FAQ,
    VIEW_ABOUT,
    UPDATE_ABOUT,
    CUSTOMER,
    PROFESSIONAL,
    VIEW_DASHBOARD,
    VIEW_STATISTICS,
    VIEW_PAYMENTS
}
